<template>
  <div class="test">
    <div class="test__choice" v-if="currentBlock === null">
      <div class="test__choice-block">
        <div class="test__choice-block-person">
          <div class="test__choice-block-img">
            <img
              class="mx-auto"
              :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/betalock-landing/test__choice-block1.svg
              `"
            />
          </div>
          <div class="test__choice-block-text">
            <div class="test__choice-block-name" style="color: #3e1876">
              Виктор С.*
            </div>
            <div class="test__choice-block-age">60 лет</div>
          </div>
        </div>

        <div
          @click="setCurrentBlock(0)"
          class="
            test__choice-block-button
            button button_violet
            mx-auto
            mt-8
            px-8
          "
        >
          Пройти задачу
        </div>
      </div>
      <div class="test__choice-block">
        <div class="test__choice-block-person">
          <div class="test__choice-block-img">
            <img
              class="mx-auto"
              :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/betalock-landing/test__choice-block2.svg
              `"
            />
          </div>
          <div class="test__choice-block-text">
            <div class="test__choice-block-name" style="color: #830051">
              Виктория К.*
            </div>
            <div class="test__choice-block-age">35 лет</div>
          </div>
        </div>
        <div
          @click="setCurrentBlock(1)"
          class="
            test__choice-block-button
            button button_pink-light
            mx-auto
            mt-8
            px-8
          "
        >
          Пройти задачу
        </div>
      </div>
    </div>
    <div class="test__detail" v-if="currentBlock === 0">
      <div class="test__detail-person">
        <div class="test__detail-person-img">
          <img
            :srcset="`
              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/betalock-landing/test__choice-block1.svg
            `"
          />
        </div>
        <div class="test__detail-person-text">
          <div class="test__detail-person-name">Виктор С.*</div>
          <div class="test__detail-person-age">60 лет</div>
        </div>
      </div>
      <div class="test__detail-text">
        <div class="test__detail-text-description">
          К вам обратился мужчина 60 лет с жалобами на частые эпизоды снижения
          ЧСС до 40 ударов в минуту, которые субъективно не ощущает, но замечает
          при измерении на автоматическом тонометре. Потери сознания отрицает.
          Других жалоб нет. При осмотре обращает на себя внимание брадикардия –
          ЧСС 42 уд/мин.
          <br />
          <br />
          Из анамнеза известно, что в течение 15 лет страдает гипертонической
          болезнью с максимальными цифрами АД до 200/110 мм рт.ст.,
          дислипидемией 2а типа. На фоне получаемой терапии ЛПНП и артериальное
          давление в целевых значениях . Около 5 лет назад перенес инфаркт
          миокарда (проводилась коронарангиография, балонная ангиопластика и
          стентирование передней нисходящей артерии). По данным Эхо-КГ около
          года назад выявлена хроническая сердечная недостаточность со сниженной
          фракцией выброса (38%). ОНМК, сахарный диабет 2 типа в анамнезе
          отрицает.
        </div>
        <div class="test__detail-text-title mt-10 mb-5">
          В данный момент пациент принимает:
        </div>
        <ul class="test__detail-text-list">
          <li>Сакубитрил/Валсартан 50 мг (25.7 мг+24.3 мг) 2 раза в день</li>
          <li>Метопролола сукцинат 100 мг 1 раз в день</li>
          <li>Верапамил 80 мг 3 раза в день</li>
          <li>Спиронолактон 25 мг</li>
          <li>Дапаглифлозин 10 мг</li>
          <li>Ацетилсалициловая кислота 100 мг 1 раз в день</li>
          <li>Розувастатин 20 мг 1 раз в день</li>
        </ul>
        <div class="test__detail-text-title mt-10 mb-5">
          Скорректируйте схему лечения с учетом:
        </div>
        <ul class="test__detail-text-list">
          <li>Жалоб пациента</li>
          <li>Риска межлекарственных взаимодействий</li>
          <li>
            Алгоритмов оптимального подбора терапии пациенту с ИБС и ХСНсФВ
          </li>
        </ul>
        <div
          class="test-block__question-status-block"
          :class="[
            `test-block__question-status-block_${tips[0][conditionIndex].status}`,
          ]"
          v-if="answeredQuestions[0]"
        >
          <div class="test-block__question-status">
            <div class="test-block__question-status-icon">
              <img
                v-if="tips[0][conditionIndex].status === true"
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/betalock-landing/test-correct.svg`"
              />
              <img
                v-if="tips[0][conditionIndex].status === false"
                :src="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/betalock-landing/test-uncorrect.svg
                `"
              />
              <img
                v-if="tips[0][conditionIndex].status === null"
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/betalock-landing/test-maybe.svg`"
              />
            </div>
            <div class="test-block__question-status-text">
              <span
                style="color: #1fa163"
                v-if="tips[0][conditionIndex].status === true"
                >Правильный ответ!</span
              >
              <span
                style="color: #f0383a"
                v-if="tips[0][conditionIndex].status === false"
                >Неправильный ответ!
              </span>
              <span
                style="color: #f0ab00"
                v-if="tips[0][conditionIndex].status === null"
                >Вы на верном пути!</span
              >
            </div>
          </div>
          <div
            class="test-block__question-tip"
            v-html="tips[0][conditionIndex].text"
          ></div>
        </div>
        <div class="test__detail-test">
          <div class="test-block__question-answers">
            <label
              class="test-block__question-answer"
              v-for="(answer, aInd) in questions[0].answers"
              :key="aInd"
            >
              <input
                :value="`${aInd + 1}`"
                :type="questions[0].type === 'once' ? 'radio' : 'checkbox'"
                v-model="answers[0]"
                @change="resetTip(0)"
              />
              <div class="test-block__question-answer-container">
                <div
                  class="test-block__question-answer-radio"
                  v-if="questions[0].type === 'once'"
                >
                  <div></div>
                </div>
                <div class="test-block__question-answer-check" v-else>
                  <div>
                    <svg
                      width="12"
                      height="10"
                      viewBox="0 0 12 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5196 0C10.1238 0 9.75184 0.162293 9.47191 0.456982L3.98971 6.23613L2.52856 4.69847C2.24881 4.40398 1.87686 4.24176 1.48122 4.24176C1.08552 4.24176 0.713561 4.40398 0.433753 4.6984C0.154011 4.99282 0 5.38428 0 5.80067C0 6.21706 0.154011 6.60853 0.433753 6.90288L2.94257 9.54329C3.22231 9.83778 3.59427 10 3.98997 10C4.38548 10 4.75757 9.83771 5.03763 9.54302L11.567 2.66132C12.1444 2.05342 12.1443 1.06441 11.567 0.456505C11.2872 0.162157 10.9152 0 10.5196 0Z"
                        fill="#FF53FF"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="test-block__question-answer-text"
                  v-html="answer"
                ></div>
              </div>
            </label>
          </div>
        </div>
        <div
          @click="checkAnswer(0)"
          class="test-block__question-button button button_violet px-8"
        >
          Проверить ответ
        </div>
        <div @click="$emit('resetTest')" class="test-block__question-reset">
          Вернуться в начало
        </div>
      </div>
    </div>
    <div class="test__detail test__detail_pink" v-if="currentBlock === 1">
      <div class="test__detail-person">
        <div class="test__detail-person-img">
          <img
            :srcset="`
              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/betalock-landing/test__choice-block2.svg
            `"
          />
        </div>
        <div class="test__detail-person-text">
          <div class="test__detail-person-name">Виктория К.*</div>
          <div class="test__detail-person-age">35 лет</div>
        </div>
      </div>
      <div class="test__detail-text">
        <div class="test__detail-text-description">
          К вам на прием обратилась женщина 35 лет с жалобами на учащенное
          сердцебиение, потливость, раздражительность в течение последнего года,
          часто отмечает выраженное чувство тревоги. В течение года неоднократно
          обращалась к кардиологу, однако при обследовании соматической
          патологии выявлено не было, предоставила результаты ЭКГ, ЭхоКГ,
          клинического анализа крови.
          <br />
          <br />
          При осмотре обращает на себя внимание влажность кожных покровов. АД
          130/80 мм рт. ст., ЧСС – 104 уд./мин.
          <br />
          <br />
          При регистрации ЭКГ на осмотре: синусовая тахикардия 110 ударов в
          минуту, по данным измерений «умных» часов, среднесуточный уровень ЧСС
          – 102 удара в минуту. При повторном выполнении лабораторных
          исследований: в клиническом анализе крови гемоглобин 130 г/л,
          эритроцитарные индексы в рамках референтных значений.
          <br />
          <br />
          Госпитальная Шкала Тревоги и Депрессии (HADS) 11/2
        </div>
        <div class="test__detail-text-title mt-10 mb-5">
          Выберете дальнейшую тактику ведения пациентки:
        </div>
        <div
          class="test-block__question-status-block"
          :class="[
            `test-block__question-status-block_${tips[1][conditionIndex].status}`,
          ]"
          v-if="answeredQuestions[1]"
        >
          <div class="test-block__question-status">
            <div class="test-block__question-status-icon">
              <img
                v-if="tips[1][conditionIndex].status === true"
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/betalock-landing/test-correct.svg`"
              />
              <img
                v-if="tips[1][conditionIndex].status === false"
                :src="`
                  https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/betalock-landing/test-uncorrect.svg
                `"
              />
              <img
                v-if="tips[1][conditionIndex].status === null"
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/betalock-landing/test-maybe.svg`"
              />
            </div>
            <div class="test-block__question-status-text">
              <span
                style="color: #1fa163"
                v-if="tips[1][conditionIndex].status === true"
                >Правильный ответ!</span
              >
              <span
                style="color: #f0383a"
                v-if="tips[1][conditionIndex].status === false"
                >Неправильный ответ!
              </span>
              <span
                style="color: #f0ab00"
                v-if="tips[1][conditionIndex].status === null"
                >Вы на верном пути!</span
              >
            </div>
          </div>
          <div
            class="test-block__question-tip"
            v-html="tips[1][conditionIndex].text"
          ></div>
        </div>
        <div class="test__detail-test">
          <div class="test-block__question-answers">
            <label
              class="test-block__question-answer"
              v-for="(answer, aInd) in questions[1].answers"
              :key="aInd"
            >
              <input
                :value="`${aInd + 1}`"
                :type="questions[1].type === 'once' ? 'radio' : 'checkbox'"
                v-model="answers"
                @change="resetTip(1)"
              />
              <div class="test-block__question-answer-container">
                <div
                  class="test-block__question-answer-radio"
                  v-if="questions[1].type === 'once'"
                >
                  <div></div>
                </div>
                <div class="test-block__question-answer-check" v-else>
                  <div>
                    <svg
                      width="12"
                      height="10"
                      viewBox="0 0 12 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5196 0C10.1238 0 9.75184 0.162293 9.47191 0.456982L3.98971 6.23613L2.52856 4.69847C2.24881 4.40398 1.87686 4.24176 1.48122 4.24176C1.08552 4.24176 0.713561 4.40398 0.433753 4.6984C0.154011 4.99282 0 5.38428 0 5.80067C0 6.21706 0.154011 6.60853 0.433753 6.90288L2.94257 9.54329C3.22231 9.83778 3.59427 10 3.98997 10C4.38548 10 4.75757 9.83771 5.03763 9.54302L11.567 2.66132C12.1444 2.05342 12.1443 1.06441 11.567 0.456505C11.2872 0.162157 10.9152 0 10.5196 0Z"
                        fill="#d0006f"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="test-block__question-answer-text"
                  v-html="answer"
                ></div>
              </div>
            </label>
          </div>
        </div>
        <div
          @click="checkAnswer(1)"
          class="test-block__question-button button button_pink-light px-8"
        >
          Проверить ответ
        </div>
        <div @click="$emit('resetTest')" class="test-block__question-reset">
          Вернуться в начало
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestBlock",
  data: () => ({
    currentBlock: null,
    answers: [],
    answeredQuestions: [false, false],
    conditionIndex: 0,
    questions: [
      {
        type: "once",
        answers: [
          "Сакубитрил/Валсартан 50 мг (25.7 мг+24.3 мг) 2 раза в день",
          "Метопролола сукцинат 100 мг 1 раз в день",
          "Верапамил 80 мг 3 раза в день",
          "Спиронолактон 25 мг 1 р в день",
          "Дапаглифлозин 10 мг 1 р в день",
          "Ацетилсалициловая кислота 100 мг 1 раз в день",
          "Розувастатин 20 мг 1 раз в день",
        ],
      },
      {
        type: "more",
        answers: [
          "Дополнительное обследование не требуется, назначить растительные седативные препараты или БАДы",
          "Необходимо дообследование для исключения функциональной тахикардии",
          "Консультация психиатра/психотерапевта для исключения тревожного расстройства",
          "С учетом выраженных симптомов назначить бета-адреноблокатор под контролем ЧСС",
        ],
      },
    ],
  }),
  computed: {
    answersConditions() {
      return [
        [[["1"]], [["2"]], [["3"]], [["4"]], [["5"]], [["6"]], [["7"]]],
        [
          [["1"]],
          [["2", "3", "4"]],
          [["3", "4"], ["2", "4"], ["4"]],
          [["1", "2", "3", "4"]],
          [["1", "2", "4"]],
          [["1", "2", "3"]],
          [["1", "3", "4"]],
          [["2", "3"]],
        ],
      ];
    },
    tips() {
      return [
        [
          {
            status: false,
            text: `Пациенту с ИБС и ХСН со сниженной фракцией выброса показан прием 4 основных групп препаратов: иАПФ или АРНИ, бета-адреноблокаторы, АМКР и иНГЛТ-2.
          <br>
          <br>
          Узнать подробнее о терапии ишемической болезни сердца и хронической сердечной недостаточности. <a href="#indications">Подробнее</a>`,
          },
          {
            status: false,
            text: `Пациенту с ИБС и ХСН со сниженной фракцией выброса показан прием 4 основных групп препаратов: иАПФ или АРНИ, бета-адреноблокаторы, АМКР и иНГЛТ-2.
          <br>
          <br>
          Узнать подробнее о терапии ишемической болезни сердца и хронической сердечной недостаточности. <a href="#indications">Подробнее</a>`,
          },
          {
            status: true,
            text: `Следует избегать совместного приема метопролола сукцината и верапамила, так как совместный прием данных препаратов повышает риск брадикардии и гипотензии.
          <br>
          <br>
          С какими еще препаратами следует избегать назначение метапролола сукцината? <a href="#exclude">Подробнее</a>`,
          },
          {
            status: false,
            text: `Пациенту с ИБС и ХСН со сниженной фракцией выброса показан прием 4 основных групп препаратов: иАПФ или АРНИ, бета-адреноблокаторы, АМКР и иНГЛТ-2.
          <br>
          <br>
          Узнать подробнее о терапии ишемической болезни сердца и хронической сердечной недостаточности. <a href="#indications">Подробнее</a>`,
          },
          {
            status: false,
            text: `Пациенту с ИБС и ХСН со сниженной фракцией выброса показан прием 4 основных групп препаратов: иАПФ или АРНИ, бета-адреноблокаторы, АМКР и иНГЛТ-2.
          <br>
          <br>
          Узнать подробнее о терапии ишемической болезни сердца и хронической сердечной недостаточности. <a href="#indications">Подробнее</a>`,
          },
          {
            status: false,
            text: `Для вторичной профилактики сердечно-сосудистых событий показан прием ацетилсалициловой кислоты 1 раз в день в дозе 75-100 мг в сутки. 
          <br>
          <br>
          Узнать подробнее о терапии ишемической болезни сердца <a target="_blank" href="https://cr.minzdrav.gov.ru/recomend/155_1">Подробнее</a>`,
          },
          {
            status: false,
            text: `Для вторичной профилактики сердечно-сосудистых событий показан прием гиполипидемической терапии.
          <br>
          <br>
          Узнать подробнее о терапии ишемической болезни сердца <a target="_blank" href="https://cr.minzdrav.gov.ru/recomend/155_1">Подробнее</a>`,
          },
        ],
        [
          {
            status: false,
            text: `Имеющихся данных недостаточно для уточнения причины синусовой тахикардии у молодой пациентки. Предлагаем ознакомиться с нашей статьей <a href="#">Подробнее</a>`,
          },
          {
            status: true,
            text: `С учетом выраженности симптомов, на время обследования целесообразно назначить ритмурежающую – бета-адреноблокаторы под контролем ЧСС.`,
          },
          {
            status: null,
            text: `Можно расширить проводимое обследование для уточнения причины тахикардии у пациентки. Предлагаем ознакомиться с нашей статьей <a href="#">Подробнее</a>`,
          },
          {
            status: null,
            text: `Однако седативные средства растительного происхождения не рекомендованы для терапии тахикардии.`,
          },
          {
            status: null,
            text: `Седативные средства растительного происхождения не рекомендованы для терапии тахикардии. 
            <br>
            <br>
            Можно расширить проводимое обследование для уточнения причины тахикардии у пациентки. Предлагаем ознакомиться с нашей статьей <a href="#">Подробнее</a>`,
          },
          {
            status: null,
            text: `Седативные средства растительного происхождения не рекомендованы для терапии тахикардии.
            <br>
            <br>
            С учетом выраженных симптомов целесообразно на время обследования и уточнения причины тахикардии назначить лекарственную терапию под контролем ЧСС. Предлагаем ознакомиться с нашей статьей <a href="#">Подробнее</a>`,
          },
          {
            status: null,
            text: `Седативные средства растительного происхождения не рекомендованы для терапии тахикардии.
            <br>
            <br>
            Можно расширить проводимое обследование для уточнения причины тахикардии у пациентки. Предлагаем ознакомиться с нашей статьей <a href="#">Подробнее</a>`,
          },
          {
            status: null,
            text: `Можно расширить проводимое обследование для уточнения причины тахикардии у пациентки.
            <br>
            <br>
            С учетом выраженных симптомов целесообразно на время обследования и уточнения причины тахикардии назначить лекарственную терапию под контролем ЧСС. Предлагаем ознакомиться с нашей статьей <a href="#">Подробнее</a>`,
          },
        ],
      ];
    },
  },
  methods: {
    getConditionIndex(qInd) {
      return this.answersConditions[qInd].findIndex(
        (el) =>
          el.findIndex(
            (c) =>
              JSON.stringify(c) === JSON.stringify([...this.answers].sort())
          ) > -1
      );
    },
    checkAnswer(qInd) {
      this.$set(this.answeredQuestions, qInd, true);
      this.$set(this, "conditionIndex", this.getConditionIndex(qInd));
    },
    resetTip(qInd) {
      this.$set(this.answeredQuestions, qInd, false);
      this.$set(this, "conditionIndex", this.getConditionIndex(qInd));
    },
    setCurrentBlock(val) {
      this.$set(this, "currentBlock", val);
      document
        .querySelector(".test-block__title")
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  &__choice {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 50px;
    background-color: #f3f1f5;
    border: 2px solid #d8cfdde6;
    border-radius: 32px;
    box-shadow: 0px 30px 60px -30px #00000059;

    @media screen and (max-width: 1219px) {
      grid-template-columns: 100%;
      padding: 20px 45px;
    }

    &-block {
      &:nth-child(1) {
        border-right: 2px solid #d8cfdde5;
        @media screen and (max-width: 1219px) {
          border-right: 0;
          border-bottom: 2px solid #d8cfdde5;
          padding-bottom: 30px;
          margin-bottom: 30px;
        }
      }

      &-person {
        @media screen and (max-width: 1219px) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &-img {
        margin-bottom: 40px;
         @media screen and (max-width: 1219px) {
          margin-bottom: 0;
          width: 100px;
          margin-right: 20px;

          & img {
            width: 100%;
          }
         }
      }

      &-name {
        margin-bottom: 10px;
        font-family: "Roboto Slab", sans-serif;
        font-size: 23px;
        font-weight: 500;
        line-height: 29px;
        text-align: center;

         @media screen and (max-width: 1219px) {
          text-align: left;
          font-size: 18px;
          line-height: 22px;
         }
      }

      &-age {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        color: #1f1f1f;

        @media screen and (max-width: 1219px) {
          text-align: left;
         }
      }

      &-button {
        width: fit-content;
      }
    }
  }

  &__detail {
    --backColor: #f3f1f5;
    --titleColor: #3e1876;
    --inputColor: #3e1876;
    --backButtonColor: #3e1876;

    &_pink {
      --back: #f8f0f5;
      --titleColor: #830051;
      --inputColor: #d0006f;
      --backButtonColor: #d0006f;
    }

    padding: 50px;
    background-color: var(--backColor);
    border: 2px solid #d8cfdde6;
    border-radius: 32px;
    box-shadow: 0px 30px 60px -30px #00000059;

     @media screen and (max-width: 1219px) {
      padding: 20px;
     }

    &-person {
      margin-bottom: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-img {
        width: 125px;
        margin-right: 30px;

         @media screen and (max-width: 1219px) {
          width: 100px;
          margin-right: 20px;
         }

        & img {
          max-width: 100%;
        }
      }

      &-name {
        margin-bottom: 10px;
        font-family: "Roboto Slab", sans-serif;
        font-size: 23px;
        font-weight: 500;
        line-height: 29px;
        color: var(--titleColor);

        @media screen and (max-width: 1219px) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      &-age {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #1f1f1f;

        @media screen and (max-width: 1219px) {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }

    &-text {
      &-title {
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        text-align: left;
        color: var(--titleColor);
      }

      &-list {
        & li {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          text-align: left;
          color: #1f1f1f;

          & b {
            font-weight: 700;
          }

          &:not(:last-child) {
            margin-bottom: 16px;
            @media screen and (max-width: 1219px) {
          margin-bottom: 8px;
        }
          }

          &::before {
            flex-shrink: 0;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-top: 8px;
            margin-right: 10px;
            background-color: var(--titleColor);
            content: "";
            display: block;
          }
        }
      }

      &-description {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;

        @media screen and (max-width: 1219px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

.test-block {
  &__question {
    &-answers {
      margin-top: 25px;
    }
    &-answer {
      display: block;
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &-container {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      &-radio {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: #fff;
        border: 2px solid var(--inputColor);

        & > * {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          opacity: 0;
          background-color: var(--inputColor);
        }
      }

      &-check {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        border-radius: 5px;
        background-color: #fff;
        border: 2px solid var(--inputColor);

        & > * {
          width: 12px;
          height: 10px;
          opacity: 0;
        }
      }

      &-text {
        margin-left: 16px;
        font-family: "Roboto Slab", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #1f1f1f;

        @media screen and (max-width: 1024px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      & input {
        display: none;

        &:checked {
          & ~ .test-block__question-answer-container {
            & .test-block__question-answer {
              &-radio {
                & > * {
                  opacity: 1;
                }
              }

              &-check {
                & > * {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

    &-status {
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-block {
        margin-top: 25px;
        padding: 20px 30px;
        border-radius: 0 32px 32px 0;
        background-color: #fff;
        border: 1px solid #d2d2d2;
        border-left: 7px solid #fff;
        box-shadow: 0px 20px 35px -20px #00000059;

        @media screen and (max-width: 1024px) {
          padding: 20px;
        }

        &_true {
          border-left-color: #1fa163;
        }
        &_false {
          border-left-color: #cf1517;
        }
        &_null {
          border-left-color: #f0ab00;
        }
      }

      &-icon {
        margin-right: 16px;
      }

      &-text {
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;

        @media screen and (max-width: 1024px) {
         font-size: 16px;
          line-height: 22px;
        }

        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    &-button {
      width: fit-content;
      margin: 30px auto 20px;
    }

    &-reset {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      text-align: center;
      color: var(--inputColor);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.test-block {
  &__question {
    &-tip {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #1f1f1f;
      & a {
        font-weight: 600;
        color: #3e1876;
        text-decoration: underline;
      }
    }
  }
}
</style>